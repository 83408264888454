<template>
  <fragment>
    <v-col
      v-for="(iten, index) in apis"
      :key="index"
      cols="3"
    >
      <v-card
        elevation="24"
        outlined
        min-height="300px"
      >
        <v-card-text>
          <p class="text-h6 text--primary">
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-avatar
                  v-if="iten.logo === null"
                  color="primary"
                  size="60"
                >
                  <span class="white--text text-h5">{{ iten.api_name[0] }}</span>
                </v-avatar>
                <v-avatar
                  v-else
                  class="profile"
                  size="60"
                  tile
                >
                  <v-img
                    :src="rutaPublic + iten.logo"
                    :alt="iten.slug"
                    contain
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                {{ iten.api_name }}
              </v-col>
            </v-row>
          </p>
          <div class="text--primary">
            <v-tooltip
              top
              color="primary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iten.description | truncate(150) }}
                </span>
              </template>
              <span>{{ iten.description }}</span>
            </v-tooltip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-tooltip
            v-if="showEdit(iten)"
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="editItem(iten)"
              >
                <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.edit') }}</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-switch
            v-model="iten.active"
            :disabled="showEditSwitchActive(iten)"
            @change="showEditActive(iten)"
          ></v-switch>
        </v-card-actions>
      </v-card>
    </v-col>
    <!--<div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                {{ $t('lbl.name') }}
              </th>
              <th class="text-uppercase">
                {{ $t('lbl.description') }}
              </th>
              <th class="text-uppercase">
                {{ $t('lbl.active') }}
              </th>
              <th
                v-if="showTr"
                class="text-uppercase"
              >
                {{ $t('lbl.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(iten, index) in apis"
              :key="index"
            >
              <td>
                <v-avatar
                  v-if="iten.logo === null"
                  color="primary"
                  size="48"
                >
                  <span class="white--text text-h5">{{ iten.api_name[0] }}</span>
                </v-avatar>
                <v-avatar
                  v-else
                  class="profile"
                  size="60"
                  tile
                >
                  <v-img
                    :src="rutaPublic + iten.logo"
                    :alt="iten.slug"
                    contain
                  ></v-img>
                </v-avatar>
                {{ iten.api_name }}
              </td>
              <td>
                <v-tooltip
                  v-if="iten.description"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ iten.description }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-switch
                  v-model="iten.active"
                  :disabled="showEditSwitchActive(iten)"
                  @change="showEditActive(iten)"
                ></v-switch>
              </td>
              <td
                v-if="showTr"
                width="10%"
              >
                <v-tooltip
                  v-if="showEdit(iten)"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(iten)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              total-visible="5"
              @input="onPageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      -- MOSTRAR ACTIVE --
    </div>-->
    <v-dialog
      v-model="isDialogVisibleEdit"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.edit')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteToken') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="canceleEditActive"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirEditActive()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SidebarDataAPI
      v-if="isSidebarDataAPIActive"
      v-model="isSidebarDataAPIActive"
      :api="itemEdit"
      :type_api="type_api"
      :proveedores="proveedores"
      @confirmEdit="confirmEdit"
    />
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiInformationOutline,
} from '@mdi/js'

import SidebarDataAPI from './SidebarDataAPI.vue'

export default {
  components: {
    SidebarDataAPI,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    apis: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    type_api: {
      type: String,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiInformationOutline,
      },
      isDialogVisible: false,
      isDialogVisibleEdit: false,
      itemId: null,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      loading: false,
      isSidebarDataAPIActive: false,
      itemEdit: {},
      proveedores: [],
      clients: {
        client_id: null,
        active: true,
      },
    }
  },
  computed: {
    ...mapState({
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    disabledActive() {
      let result = true

      if (this.type_api === 'gds') {
        if (
          this.permisos.includes('api_tti:edit')
          || this.permisos.includes('api_amadeus:edit')
          || this.permisos.includes('*')
        ) {
          result = false
        }
      }

      return result
    },
    showTr() {
      let result = false

      if (this.type_api === 'gds') {
        if (
          this.permisos.includes('api_tti:edit')
          || this.permisos.includes('api_amadeus:edit')
          || this.permisos.includes('*')
        ) {
          result = true
        }
      } else if (this.type_api === 'payments') {
        if (
          this.permisos.includes('api_redsys:edit')
          || this.permisos.includes('api_sibs:edit')
          || this.permisos.includes('api_authorize_net:edit')
          || this.permisos.includes('api_paypal:edit')
          || this.permisos.includes('*')
        ) {
          result = true
        }
      } else if (this.type_api === 'exchanges_currencies') {
        if (this.permisos.includes('currencyapi:edit') || this.permisos.includes('*')) {
          result = true
        }
      } else if (this.type_api === 'contrates') {
        if (
          this.permisos.includes('api_hotetec:edit')
          || this.permisos.includes('api_dingus:edit')
          || this.permisos.includes('api_solways:edit')
          || this.permisos.includes('api_copa_connect:edit')
          || this.permisos.includes('*')
        ) {
          result = true
        }
      } else if (this.type_api === 'traslate') {
        if (
          this.permisos.includes('api_deepl:edit')
          || this.permisos.includes('*')
        ) {
          result = true
        }
      }

      return result
    },
  },
  created() {
    // this.getProveedores()
  },
  methods: {
    onPageChange() {
      this.load()
    },
    getProveedores() {
      this.axios
        .get('proveedors/byproduct/hotels', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    editItem(item) {
      this.itemEdit = item
      this.isSidebarDataAPIActive = true
    },
    confirmEdit() {
      const json = {
        id: this.itemEdit.id,
        api_name: this.itemEdit.api_name,
        key: this.itemEdit.key,
        token: this.itemEdit.token,
        url_prod: this.itemEdit.url_prod,
        logo: this.itemEdit.logo,
        description: this.itemEdit.description,
        other_fields: {
          proveedores: this.proveedorApis,
          clients: this.clientsApis,
        },

        // Redsys
        mercantil_code: this.itemEdit.mercantil_code,
        terminal: this.itemEdit.terminal,
        currency: this.itemEdit.currency,
      }
      this.axios
        .post('api_extern/update', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.isSidebarDataAPIActive = false
          this.itemEdit = {}
        })
    },
    showEditActive(iten) {
      this.itemEdit = iten
      if (!this.itemEdit.active) {
        this.isDialogVisibleEdit = true
      } else {
        this.confirEditActive()
      }
    },
    canceleEditActive() {
      this.itemEdit.active = !this.itemEdit.active
      this.isDialogVisibleEdit = false
    },
    confirEditActive() {
      this.loading = true
      this.axios
        .post(
          'api_extern/active',
          {
            id: this.itemEdit.id,
            active: this.itemEdit.active,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(res => {})
        .finally(() => {
          this.loading = false
          this.isDialogVisibleEdit = false
          this.itemEdit = {}
        })
    },

    showEditSwitchActive(iten) {
      let result = true

      if (this.type_api === 'gds') {
        if (iten.slug === 'tti-zenith' && this.permisos.includes('api_tti:edit')) {
          result = false
        } else if (iten.slug === 'amadeus' && this.permisos.includes('api_amadeus:edit')) {
          result = false
        }
      } else if (this.type_api === 'payments') {
        if (iten.slug === 'redsys' && this.permisos.includes('api_redsys:edit')) {
          result = false
        } else if (iten.slug === 'sibs' && this.permisos.includes('api_sibs:edit')) {
          result = false
        } else if (iten.slug === 'authorize-net' && this.permisos.includes('api_authorize_net:edit')) {
          result = false
        } else if (iten.slug === 'paypal' && this.permisos.includes('api_paypal:edit')) {
          result = false
        }
      } else if (this.type_api === 'exchanges_currencies') {
        if (iten.slug === 'currencyapi' && this.permisos.includes('currencyapi:edit')) {
          result = false
        }
      } else if (this.type_api === 'contrates') {
        if (iten.slug === 'hotetecapi' && this.permisos.includes('api_hotetec:edit')) {
          result = false
        } else if (iten.slug === 'dingusapi' && this.permisos.includes('api_dingus:edit')) {
          result = false
        } else if (iten.slug === 'juniperapi' && this.permisos.includes('api_juniper:edit')) {
          result = false
        } else if (iten.slug === 'copa_connect_api' && this.permisos.includes('api_copa_connect:edit')) {
          result = false
        } else if (iten.slug === 'solwaysapi' && this.permisos.includes('api_solways:edit')) {
          result = false
        }
      } else if (this.type_api === 'traslate') {
        if (iten.slug === 'deeplapi' && this.permisos.includes('api_deepl:edit')) {
          result = false
        }
      }

      return result
    },
    showEdit(iten) {
      let result = false

      if (this.type_api === 'gds') {
        if (iten.slug === 'tti-zenith' && this.permisos.includes('api_tti:edit')) {
          result = true
        } else if (iten.slug === 'amadeus' && this.permisos.includes('api_amadeus:edit')) {
          result = true
        }
      } else if (this.type_api === 'payments') {
        if (iten.slug === 'redsys' && this.permisos.includes('api_redsys:edit')) {
          result = true
        } else if (iten.slug === 'sibs' && this.permisos.includes('api_sibs:edit')) {
          result = true
        } else if (iten.slug === 'authorize-net' && this.permisos.includes('api_authorize_net:edit')) {
          result = true
        } else if (iten.slug === 'paypal' && this.permisos.includes('api_paypal:edit')) {
          result = true
        }
      } else if (this.type_api === 'exchanges_currencies') {
        if (iten.slug === 'currencyapi' && this.permisos.includes('currencyapi:edit')) {
          result = true
        }
      } else if (this.type_api === 'contrates') {
        if (iten.slug === 'hotetecapi' && this.permisos.includes('api_hotetec:edit')) {
          result = true
        } else if (iten.slug === 'dingusapi' && this.permisos.includes('api_dingus:edit')) {
          result = true
        } else if (iten.slug === 'juniperapi' && this.permisos.includes('api_juniper:edit')) {
          result = true
        } else if (iten.slug === 'copa_connect_api' && this.permisos.includes('api_copa_connect:edit')) {
          result = true
        } else if (iten.slug === 'solwaysapi' && this.permisos.includes('api_solways:edit')) {
          result = true
        }
      } else if (this.type_api === 'traslate') {
        if (iten.slug === 'deeplapi' && this.permisos.includes('api_deepl:edit')) {
          result = true
        }
      }

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
